import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Greeting from "../components/Greeting";
import greetings from "../data/greetings";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <>
      <SEO title="About Oscar" keywords={[`Oscar Hillestad`, `Project Manager`]} mdxType="SEO" />
      <Greeting greetings={greetings} mdxType="Greeting" />
      <p>{`Feeling just as comfortable pushing code as I do in a board room, I've always taken great pride in making sure my development and sales teams aim to achieve excellence in all the digital experiences we deliver. Being the link between management/sales and IT/product development is my native habitat, and I enjoy the subtle, but important art of adapting a narrative depending on the context and audience.`}</p>
      <p>{`I'd like to think my colleagues find me an energetic and ambitious team player, who loves Mondays and likes to put a smile on as many faces as possible over the course of a work day. It's important for me to surround myself with like-minded people, who share my vision of building something great and having fun doing it.`}</p>
      <h2>{`I love:`}</h2>
      <ul>
        <li parentName="ul">{`Building great stuff`}</li>
        <li parentName="ul">{`Coaching great people`}</li>
        <li parentName="ul">{`Tech-business bridging`}</li>
      </ul>
      <h2>{`These are some of the technical toolkits I possess:`}</h2>
      <ul>
        <li parentName="ul">{`Web (HTML/CSS/JS)`}</li>
        <li parentName="ul">{`Database/BI (SQL)`}</li>
        <li parentName="ul">{`Product design (Sketch/Photoshop/Figma)`}</li>
        <li parentName="ul">{`Marketing (Google Ads)`}</li>
        <li parentName="ul">{`Project management (Asana/Jira/YouTrack)`}</li>
        <li parentName="ul">{`Calculation (Excel)`}</li>
      </ul>
      <h2>{`These are some of the folks I've worked with:`}</h2>
      <ul>
        <li parentName="ul">{`SAS`}</li>
        <li parentName="ul">{`E.ON`}</li>
        <li parentName="ul">{`American Express`}</li>
        <li parentName="ul">{`DNB`}</li>
        <li parentName="ul">{`Handelsbanken`}</li>
        <li parentName="ul">{`Klarna`}</li>
        <li parentName="ul">{`Sector Alarm`}</li>
        <li parentName="ul">{`Mastercard`}</li>
      </ul>
      <h2>{`I enjoy:`}</h2>
      <ul>
        <li parentName="ul">{`Craft brews`}</li>
        <li parentName="ul">{`Good food`}</li>
        <li parentName="ul">{`Meeting new people`}</li>
        <li parentName="ul">{`Spending time with people I care about`}</li>
        <li parentName="ul">{`A good game`}</li>
      </ul>
      <h2>{`I worked as:`}</h2>
      <ul>
        <li parentName="ul">{`Technical Partner Manager, WirelessCar (2021-)`}</li>
        <li parentName="ul">{`CTO/CPO, Awardit (2019-2021)`}</li>
        <li parentName="ul">{`Sales & Marketing Manager / KAM, Crossroads Loyalty Solutions (2011-2019)`}</li>
      </ul>
      <h2>{`I studied at:`}</h2>
      <ul>
        <li parentName="ul">{`School of Business, Economics and Law at the University of Gothenburg (2006-2010)`}</li>
        <li parentName="ul">{`Santa Barbara City College (2007)`}</li>
      </ul>
    </>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      